define("discourse/plugins/discourse-weekly-newsletter/discourse/initializers/receive-newsletter", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "receive-newsletter",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.22", api => {
        api.modifyClass("controller:preferences/emails", {
          actions: {
            save() {
              this.get("saveAttrNames").push("custom_fields");
              this._super();
            }
          }
        });
      });
    }
  };
});